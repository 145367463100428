import {
  FAVOURITE_DETAIL_FAIL,
  FAVOURITE_DETAIL_REQUEST,
  FAVOURITE_DETAIL_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  favourite: {},
  loading: false,
  error: null,
};

const favouriteDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FAVOURITE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAVOURITE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        favourite: { ...state.favourite, ...payload },
      };
    case FAVOURITE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default favouriteDetailReducer;
