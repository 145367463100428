import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Divider,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Footer = ({ isLanding }) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 816px");
  const isApple = window.innerWidth <= 816;

  return (
    <>
      <Box style={{ marginTop: `${isMobile || isApple ? '0px' : '50px'}`,backgroundColor: `${isMobile || isApple ? 'transparent' : "#efeeef"}` }}>
        <Container>
          {isMobile || isApple ? (
            <Stack fullWidth direction="column" style={{marginBottom: '14px'}} >
              <Divider>
                <img
                  src="/assets/logo_transparent.png"
                  style={{ width: '400px',marginBottom: "10px" }}
                  alt="logo"
                />
              </Divider>
            <Stack fullWidth direction="column" style={{marginBottom: '4px'}}>
              
            </Stack>
              <Accordion style={{border: "none"}} >
                <AccordionSummary
                style={{border: "none"}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="head2">
                    <strong>ABOUT US</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => history.push("/about")}
                  >
                    <Typography>About Us</Typography>
                  </Link>
                </AccordionDetails>
                <AccordionDetails>
                  <Link underline="hover" color="inherit">
                    <Typography>Contact Us</Typography>
                  </Link>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="head2">
                    <strong>HELP</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => history.push("/faqs")}
                    >
                      <Typography>FAQs</Typography>
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => history.push("/faqs")}
                    >
                      <Typography>Cookie Policy</Typography>
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => history.push("/faqs")}
                    >
                      <Typography>Dispatch & Delivery</Typography>
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => history.push("/faqs")}
                    >
                      <Typography>Terms & Conditions</Typography>
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => history.push("/faqs")}
                    >
                      <Typography>Privacy Policy</Typography>
                    </Link>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="head2">
                    <strong>PAYMENT</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Link underline="hover" color="inherit">
                    <Typography>Visa</Typography>
                  </Link>
                  <Link underline="hover" color="inherit">
                    <Typography>Mastercard</Typography>
                  </Link>
                </AccordionDetails>
              </Accordion>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ padding: "24px" }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="h6">
                  <strong>About Us</strong>
                </Typography>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/about")}
                >
                  <Typography>About Us</Typography>
                </Link>
                <Link underline="hover" color="inherit">
                  <Typography>Contact Us</Typography>
                </Link>
              </Stack>

              <Stack direction="column" spacing={2}>
                <Typography variant="h6">
                  <strong>Help</strong>
                </Typography>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/faqs")}
                >
                  <Typography>FAQs</Typography>
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/cookie")}
                >
                  <Typography>Cookie Policy</Typography>
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/dispatch")}
                >
                  <Typography>Dispatch & Delivery</Typography>
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/terms")}
                >
                  <Typography>Terms & Conditions</Typography>
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => history.push("/privacy")}
                >
                  <Typography>Privacy Policy</Typography>
                </Link>
              </Stack>

              <Stack direction="column" spacing={2}>
                <Typography variant="h6">
                  <strong>Contact Us</strong>
                </Typography>
                <Link underline="hover" color="inherit">
                  <Typography>support@free-cards.co.uk</Typography>
                </Link>
                <Link underline="none" color="inherit">
                  <Typography>Free-Cards</Typography>
                  <Typography>27 Old Gloucester St</Typography>
                  <Typography>London</Typography>
                  <Typography>WC1N 3AX</Typography>
                </Link>
              </Stack>
            </Stack>
          )}

          <Divider />
          {isMobile || isApple ? (
            <Stack direction="column" style={{marginBottom:'6px',padding: '4px 16px'}} spacing={1} >
            <Stack direction="row" fullWidth justifyContent="space-between" alignItems="center">
              <Typography align="left" variant="head2" style={{fontWeight: 'bold'}}>FOLLOW US</Typography>
              <Stack direction="row" spacing={2}>
                <i className="bi bi-facebook" style={{ fontSize: "22px" }} />
                <i className="bi bi-instagram" style={{ fontSize: "22px" }} />
                <i className="bi bi-twitter" style={{ fontSize: "22px" }} />
                <i className="bi bi-youtube" style={{ fontSize: "22px" }} />
              </Stack>
            </Stack>
              <Stack direction="row" spacing={4} justifyContent="space-between">
                <img
                  src="/assets/app-store-download.png"
                  style={{ height: isMobile || isApple ? "26px" : "32px" }}
                  alt="App-store"
                />
                <img
                  src="/assets/play-store-download.png"
                  style={{ height: isMobile || isApple ? "26px" : "32px" }}
                  alt="Play-store"
                />
              </Stack>
            </Stack>
          ) : ''}
          {isMobile || isApple ? (<Divider />) : ''}
          {isMobile || isApple ? '' : (


          <Stack
            direction={isMobile || isApple ? "column" : "row"}
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingY: "12px" }}
            spacing={isMobile || isApple ? 2 : 0}
          >
            <Stack
              direction={isMobile || isApple ? "column" : "row"}
              alignItems="center"
              spacing={isMobile || isApple ? 0 : 4}
            >
              <img
                src="/assets/logo_transparent.png"
                style={{ height: "45px", marginBottom: "10px" }}
                alt="logo"
              />
              <Stack direction="row" spacing={4}>
                <img
                  src="/assets/app-store-download.png"
                  style={{ height: isMobile || isApple ? "26px" : "32px" }}
                  alt="App-store"
                />
                <img
                  src="/assets/play-store-download.png"
                  style={{ height: isMobile || isApple ? "26px" : "32px" }}
                  alt="Play-store"
                />
              </Stack>
            </Stack>

            <Stack direction="column" alignItems="center">
              <Typography variant="body1">
                <strong>Follow us</strong>
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <i className="bi bi-facebook" style={{ fontSize: "22px" }} />
                <i className="bi bi-instagram" style={{ fontSize: "22px" }} />
                <i className="bi bi-twitter" style={{ fontSize: "22px" }} />
                <i className="bi bi-youtube" style={{ fontSize: "22px" }} />
              </Stack>
            </Stack>
          </Stack>
          )}
        </Container>
      </Box>

      {isMobile || isApple ? (

        <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: 1 }}
        >
          
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ float: "right" }}
          >
            &copy; 2024 Free-Cards | All rights reserved.
          </Typography>
        </Stack>
      </Container>
      ): (

      <Container
        sx={{ display: isLanding && (!isMobile || isApple) ? "block" : "none" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: 2 }}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="body2">
              <strong>Region - </strong>
            </Typography>
            <img
              src="/assets/uk_round.png"
              style={{ height: "24px" }}
              alt="flag"
            />
          </Stack>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ float: "right" }}
          >
            &copy; 2024 Free-Cards | All rights reserved.
          </Typography>
        </Stack>
      </Container>
      )}
    </>
  );
};

export default Footer;
