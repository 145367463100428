import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ThankYouPage.css";
import {
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";

const ThankYouPage = (props) => {
  const history = useHistory();
  const urlParts = props.location.pathname.split('/');

  const orderNumber = urlParts[2];
  const orderID = urlParts[3];

  const { userInfo } = useSelector((state) => state.userLogin);
  const isMobile = useMediaQuery("(max-width: 576px");
  const isApple = window.innerWidth <= 576;
  const header_style = {
    fontSize: (isMobile || isApple ? '25px' : '38px')
  }

  const content_style = {
    fontSize: (isMobile || isApple ? '21px' : '35px'),
  }

  const email_style = {
    fontSize: (isMobile || isApple ? '21px' : '35px'),
    padding: '10px 25px 0px 25px',
  }

  const goBackCheckout = () => {
    history.push('/')
    // history.push('/checkout/' +  orderID)
  }
  return (
    <>
      <div className="thank-you-page">
        <h1 style={header_style}>Thank You {userInfo?.name},</h1>
        <p style={content_style}>
          Your order has been received. Your order number is
        </p>
        <u style={content_style}><b>{orderNumber}</b></u>
        <p style={email_style}>
          We will keep you updated by e-mail.
        </p>
      
        <Button variant="text" color="primary"
          style={{
            position: "fixed",
            left: isMobile || isApple ? 0 : '',
            bottom: '10%',
            padding: "1rem",
            backgroundColor: "white",
            width: isMobile || isApple ? '100%' : '50%',

          }}
          onClick={goBackCheckout}
        >
          <Typography variant="h5">
            <strong>Go Back</strong>
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default ThankYouPage;
