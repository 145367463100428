import React from "react";
import { Button } from "@mui/material";

export default function HeaderButton() {
  return (
    <>
      <Button color="inherit" href="/products/page/1">
        <span>
          <strong>All Cards</strong>
        </span>
      </Button>
      <Button color="inherit" href="/product/Birthday/1">
        <span>
          <strong>Birthday Cards</strong>
        </span>
      </Button>
      <Button color="inherit" href="/product/Thank%20You/1">
        <span>
          <strong>Thank You</strong>
        </span>
      </Button>
      <Button color="inherit" href="/product_spec/Personalised/1">
        <span>
          <strong>Personalised</strong>
        </span>
      </Button>
      <Button color="inherit" href="/product_spec/Kids/1">
        <span>
          <strong>Kids</strong>
        </span>
      </Button>
      <Button color="inherit" href="/gifts/page/1">
        <span>
          <strong>Gifts / Giftcards</strong>
        </span>
      </Button>
    </>
  );
}
