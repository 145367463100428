import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Container,
  Typography,
  Card,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import colors from "../constant/Colors";

import {
  createFavourite,
  deleteFavourite,
} from "../../store/actions/favouriteActions";

const BirthdayCards = ({ products, favouriteList, isMobile }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const birthdayCards = products.filter((item) => item.isBirthday === true);

  const handleClick = (id) => {
    if (greetingCards.length > 0) {
      setOpen(true);
    } else {
      history.push(`/cardinfo/${id}`);
    }
  };

  const viewAllHandler = () => {
    history.push("/product/Birthday/1");
  };

  const [isWish, setIsWish] = useState([]);

  const arraysEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  useEffect(() => {
    const nowSeleted = birthdayCards.map((product, index) =>
      favouriteList?.includes(product._id)
    );
    // To prevent infinite loop
    if (!arraysEqual(nowSeleted, isWish)) {
      setIsWish(nowSeleted);
    }
  }, [favouriteList, birthdayCards]);

  const favouriteHandler = (product, index) => {
    if (userInfo) {
      if (!isWish[index]) {
        dispatch(createFavourite(userInfo._id, product));
      } else {
        dispatch(deleteFavourite(userInfo._id, product._id));
      }
    } else {
      history.push("/login");
    }
  };

  const [open, setOpen] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const greetingCards = cartItems.filter((item) => item.extra === false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <div className="cardFilters">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: 1 }}
        >
          <Typography variant="h5">
            <strong>Birthday Cards</strong>
          </Typography>
          <Typography
            variant="body1"
            onClick={viewAllHandler}
            sx={{
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
              color: colors.blue,
            }}
          >
            <strong>View All</strong>
          </Typography>
        </Stack>

        <div className="filterCardPanel">
          {birthdayCards.slice(0, 15).map((product, index) => (
            <Card key={index} className="trending-cards" sx={{ boxShadow: 3 }}>
              <div style={{ position: "relative" }}>
                <img
                  src={product.imageUrl}
                  alt=""
                  width="100%"
                  style={{ aspectRatio: "100/140" }}
                  onClick={() => handleClick(product._id)}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: isMobile ? 5 : 10,
                    right: isMobile ? 5 : 10,
                    backgroundColor: colors.lightgrey,
                    opacity: 0.9,
                    boxShadow: 2,
                    "&:hover": {
                      backgroundColor: colors.white,
                    },
                  }}
                  onClick={() => favouriteHandler(product, index)}
                >
                  {isWish[index] ? (
                    <FavoriteIcon color="error" fontSize="small" />
                  ) : (
                    <FavoriteBorderIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            </Card>
          ))}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: colors.backerror, color: colors.error }}
        >
          <InfoOutlinedIcon /> One Card Per Order
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.backerror }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "justify", color: colors.error }}
          >
            You can only have one greeting card in your order. Please finish or
            remove the current card from your basket before adding a new one.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default BirthdayCards;
