export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAIL = "PRODUCT_DETAIL_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET ";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_TOP_REQUEST = "PRODUCT_TOP_REQUEST";
export const PRODUCT_TOP_SUCCESS = "PRODUCT_TOP_SUCCESS";
export const PRODUCT_TOP_FAIL = "PRODUCT_TOP_FAIL";

export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";

export const CART_ADD_ITEM_REQUSET = "CART_ADD_ITEM_REQUSET";
export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";
export const CART_ADD_ITEM_RESET = "CART_ADD_ITEM_RESET";

export const CART_DELETE_ITEM_REQUSET = "CART_DELETE_ITEM_REQUSET";
export const CART_DELETE_ITEM_SUCCESS = "CART_DELETE_ITEM_SUCCESS";
export const CART_DELETE_ITEM_FAIL = "CART_DELETE_ITEM_FAIL";
export const CART_DELETE_ITEM_RESET = "CART_DELETE_ITEM_RESET";

export const CART_DETAIL_ITEM_REQUSET = "CART_DETAIL_ITEM_REQUSET";
export const CART_DETAIL_ITEM_SUCCESS = "CART_DETAIL_ITEM_SUCCESS";
export const CART_DETAIL_ITEM_FAIL = "CART_DETAIL_ITEM_FAIL";
export const CART_DETAIL_ITEM_RESET = "CART_DETAIL_ITEM_RESET";

export const CART_UPDATE_ITEM_REQUSET = "CART_UPDATE_ITEM_REQUSET";
export const CART_UPDATE_ITEM_SUCCESS = "CART_UPDATE_ITEM_SUCCESS";
export const CART_UPDATE_ITEM_FAIL = "CART_UPDATE_ITEM_FAIL";
export const CART_UPDATE_ITEM_RESET = "CART_UPDATE_ITEM_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LOGIN_FAIL_VIA_GOOGLE = "USER_LOGIN_FAIL_VIA_GOOGLE";
export const USER_LOGIN_FAIL_VIA_FACEBOOK = "USER_LOGIN_FAIL_VIA_FACEBOOK";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL";
export const USER_DETAIL_RESET = "USER_DETAIL_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_REQUEST_MAIL_SEND_REQUEST = "USER_REQUEST_MAIL_SEND_REQUEST";
export const USER_REQUEST_MAIL_SEND_SUCCESS = "USER_REQUEST_MAIL_SEND_SUCCESS";
export const USER_REQUEST_MAIL_SEND_FAIL = "USER_REQUEST_MAIL_SEND_FAIL";
export const USER_REQUEST_MAIL_SEND_RESET = " USER_REQUEST_MAIL_SEND_RESET";

export const SHIPPED_CONFIRM_MAIL_SEND_REQUEST =
  "SHIPPED_CONFIRM_MAIL_SEND_REQUEST";
export const SHIPPED_CONFIRM_MAIL_SEND_SUCCESS =
  "SHIPPED_CONFIRM_MAIL_SEND_SUCCESS";
export const SHIPPED_CONFIRM_MAIL_SEND_FAIL = "SHIPPED_CONFIRM_MAIL_SEND_FAIL";
export const SHIPPED_CONFIRM_MAIL_SEND_RESET =
  "SHIPPED_CONFIRM_MAIL_SEND_RESET";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";
export const PASSWORD_UPDATE_RESET = "PASSWORD_UPDATE_RESET";

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

export const CATEGORY_CREATE_FAIL = "CATEGORY_CREATE_FAIL";
export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_RESET = "CATEGORY_CREATE_RESET";

export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_RESET = "CATEGORY_DELETE_RESET";

export const CATEGORY_DETAIL_FAIL = "CATEGORY_DETAIL_FAIL";
export const CATEGORY_DETAIL_REQUEST = "CATEGORY_DETAIL_REQUEST";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";

export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_RESET = "CATEGORY_LIST_RESET";

export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";
export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_RESET = "CATEGORY_UPDATE_RESET";

export const SUPPLEMENT_CREATE_FAIL = "SUPPLEMENT_CREATE_FAIL";
export const SUPPLEMENT_CREATE_REQUEST = "SUPPLEMENT_CREATE_REQUEST";
export const SUPPLEMENT_CREATE_SUCCESS = "SUPPLEMENT_CREATE_SUCCESS";
export const SUPPLEMENT_CREATE_RESET = "SUPPLEMENT_CREATE_RESET";

export const SUPPLEMENT_DELETE_FAIL = "SUPPLEMENT_DELETE_FAIL";
export const SUPPLEMENT_DELETE_REQUEST = "SUPPLEMENT_DELETE_REQUEST";
export const SUPPLEMENT_DELETE_SUCCESS = "SUPPLEMENT_DELETE_SUCCESS";
export const SUPPLEMENT_DELETE_RESET = "SUPPLEMENT_DELETE_RESET";

export const SUPPLEMENT_DETAIL_FAIL = "SUPPLEMENT_DETAIL_FAIL";
export const SUPPLEMENT_DETAIL_REQUEST = "SUPPLEMENT_DETAIL_REQUEST";
export const SUPPLEMENT_DETAIL_SUCCESS = "SUPPLEMENT_DETAIL_SUCCESS";

export const SUPPLEMENT_LIST_FAIL = "SUPPLEMENT_LIST_FAIL";
export const SUPPLEMENT_LIST_REQUEST = "SUPPLEMENT_LIST_REQUEST";
export const SUPPLEMENT_LIST_SUCCESS = "SUPPLEMENT_LIST_SUCCESS";

export const SUPPLEMENT_UPDATE_FAIL = "SUPPLEMENT_UPDATE_FAIL";
export const SUPPLEMENT_UPDATE_REQUEST = "SUPPLEMENT_UPDATE_REQUEST";
export const SUPPLEMENT_UPDATE_SUCCESS = "SUPPLEMENT_UPDATE_SUCCESS";
export const SUPPLEMENT_UPDATE_RESET = "SUPPLEMENT_UPDATE_RESET";

export const ADDRESS_CREATE_FAIL = "ADDRESS_CREATE_FAIL";
export const ADDRESS_CREATE_REQUEST = "ADDRESS_CREATE_REQUEST";
export const ADDRESS_CREATE_SUCCESS = "ADDRESS_CREATE_SUCCESS";
export const ADDRESS_CREATE_RESET = "ADDRESS_CREATE_RESET";

export const ADDRESS_DELETE_FAIL = "ADDRESS_DELETE_FAIL";
export const ADDRESS_DELETE_REQUEST = "ADDRESS_DELETE_REQUEST";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_RESET = "ADDRESS_DELETE_RESET";

export const ADDRESS_DETAIL_FAIL = "ADDRESS_DETAIL_FAIL";
export const ADDRESS_DETAIL_REQUEST = "ADDRESS_DETAIL_REQUEST";
export const ADDRESS_DETAIL_SUCCESS = "ADDRESS_DETAIL_SUCCESS";

export const ADDRESS_LIST_FAIL = "ADDRESS_LIST_FAIL";
export const ADDRESS_LIST_REQUEST = "ADDRESS_LIST_REQUEST";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";
export const ADDRESS_LIST_RESET = "ADDRESS_LIST_RESET";

export const ADDRESS_UPDATE_FAIL = "ADDRESS_UPDATE_FAIL";
export const ADDRESS_UPDATE_REQUEST = "ADDRESS_UPDATE_REQUEST";
export const ADDRESS_UPDATE_SUCCESS = "ADDRESS_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_RESET = "ADDRESS_UPDATE_RESET";

export const NOTIFICATION_CREATE_FAIL = "NOTIFICATION_CREATE_FAIL";
export const NOTIFICATION_CREATE_REQUEST = "NOTIFICATION_CREATE_REQUEST";
export const NOTIFICATION_CREATE_SUCCESS = "NOTIFICATION_CREATE_SUCCESS";
export const NOTIFICATION_CREATE_RESET = "NOTIFICATION_CREATE_RESET";

export const NOTIFICATION_DELETE_FAIL = "NOTIFICATION_DELETE_FAIL";
export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_RESET = "NOTIFICATION_DELETE_RESET";

export const NOTIFICATION_LIST_FAIL = "NOTIFICATION_LIST_FAIL";
export const NOTIFICATION_LIST_REQUEST = "NOTIFICATION_LIST_REQUEST";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_RESET = "NOTIFICATION_LIST_RESET";

export const NOTIFICATION_UPDATE_FAIL = "NOTIFICATION_UPDATE_FAIL";
export const NOTIFICATION_UPDATE_REQUEST = "NOTIFICATION_UPDATE_REQUEST";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_RESET = "NOTIFICATION_UPDATE_RESET";

export const FAVOURITE_CREATE_FAIL = "FAVOURITE_CREATE_FAIL";
export const FAVOURITE_CREATE_REQUEST = "FAVOURITE_CREATE_REQUEST";
export const FAVOURITE_CREATE_SUCCESS = "FAVOURITE_CREATE_SUCCESS";
export const FAVOURITE_CREATE_RESET = "FAVOURITE_CREATE_RESET";

export const FAVOURITE_DELETE_FAIL = "FAVOURITE_DELETE_FAIL";
export const FAVOURITE_DELETE_REQUEST = "FAVOURITE_DELETE_REQUEST";
export const FAVOURITE_DELETE_SUCCESS = "FAVOURITE_DELETE_SUCCESS";
export const FAVOURITE_DELETE_RESET = "FAVOURITE_DELETE_RESET";

export const FAVOURITE_DETAIL_FAIL = "FAVOURITE_DETAIL_FAIL";
export const FAVOURITE_DETAIL_REQUEST = "FAVOURITE_DETAIL_REQUEST";
export const FAVOURITE_DETAIL_SUCCESS = "FAVOURITE_DETAIL_SUCCESS";

export const FAVOURITE_LIST_FAIL = "FAVOURITE_LIST_FAIL";
export const FAVOURITE_LIST_REQUEST = "FAVOURITE_LIST_REQUEST";
export const FAVOURITE_LIST_SUCCESS = "FAVOURITE_LIST_SUCCESS";
export const FAVOURITE_LIST_RESET = "FAVOURITE_LIST_RESET";

export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';
