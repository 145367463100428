import axios from "axios";
import {
  FAVOURITE_CREATE_FAIL,
  FAVOURITE_CREATE_REQUEST,
  FAVOURITE_CREATE_SUCCESS,
  FAVOURITE_CREATE_RESET,
  FAVOURITE_DELETE_FAIL,
  FAVOURITE_DELETE_REQUEST,
  FAVOURITE_DELETE_SUCCESS,
  FAVOURITE_DELETE_RESET,
  FAVOURITE_DETAIL_FAIL,
  FAVOURITE_DETAIL_REQUEST,
  FAVOURITE_DETAIL_SUCCESS,
  FAVOURITE_LIST_FAIL,
  FAVOURITE_LIST_REQUEST,
  FAVOURITE_LIST_SUCCESS,
} from "./actionTypes";
import { logout } from "./userActions";

// import Api from "../../services/Api";

export const createFavourite = (userId, product) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FAVOURITE_CREATE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${getState().userLogin.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/favourites/${userId}`,
        product,
        config
      );
      dispatch({ type: FAVOURITE_CREATE_SUCCESS, payload: data });
      dispatch({ type: FAVOURITE_CREATE_RESET });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      } else {
        dispatch({
          type: FAVOURITE_CREATE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };
};

export const listFavourites = (user) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FAVOURITE_LIST_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().userLogin.userInfo.token}`,
        },
      };
      const response = await axios.get(`/api/favourites/${user}`, config);

      dispatch({ type: FAVOURITE_LIST_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      } else {
        dispatch({
          type: FAVOURITE_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };
};

export const listFavouriteDetail = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FAVOURITE_DETAIL_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().userLogin.userInfo.token}`,
        },
      };

      const response = await axios.get(`/api/favourites/detail/${id}`, config);
      dispatch({ type: FAVOURITE_DETAIL_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      } else {
        dispatch({
          type: FAVOURITE_DETAIL_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };
};

export const deleteFavourite = (userId, productId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FAVOURITE_DELETE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${getState().userLogin.userInfo.token}`,
        },
      };
      await axios.delete(`/api/favourites/${userId}/${productId}`, config);

      dispatch({ type: FAVOURITE_DELETE_SUCCESS });
      dispatch({ type: FAVOURITE_DELETE_RESET });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      } else {
        dispatch({
          type: FAVOURITE_DELETE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };
};
