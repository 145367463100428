import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";

import MainLanding from "../components/landing/MainLanding";
import Occasions from "../components/landing/Occasions";
import BirthdayCards from "../components/landing/BirthdayCards";
import PopularGifts from "../components/landing/PopularGifts";
import TrendingCards from "../components/landing/TrendingCards";
import CelebrityCards from "../components/landing/CelebrityCards";
import KidsCards from "../components/landing/KidsCards";
import QRPanel from "../components/landing/QRPanel";
import Review from "../components/landing/Review";
import Footer from "../components/Footer";

import { listProducts } from "../store/actions/productActions";
import { listFavourites } from "../store/actions/favouriteActions";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 765px");
  const isApple = window.innerWidth <= 765;

  const { products } = useSelector((state) => state.productList);

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  // Favourite cards
  const { userInfo } = useSelector((state) => state.userLogin);
  const [favouriteList, setFavouriteList] = useState([]);
  const { success: createSuccess } = useSelector(
    (state) => state.favouriteCreate
  );
  const { success: deleteSuccess } = useSelector(
    (state) => state.favouriteDelete
  );
  useEffect(() => {
    if (userInfo) {
      dispatch(listFavourites(userInfo._id)).then((res) => {
        if (res) {
          const favourites = res.data.favourites;
          setFavouriteList(favourites.map((item) => item.product));
        }
      });
    }
  }, [dispatch, userInfo, createSuccess, deleteSuccess]);

  return (
    <Box sx={{ marginBottom: isMobile || isApple ? 2 : 3 }}>
      <MainLanding />
      <Occasions />
      <BirthdayCards
        products={products}
        favouriteList={favouriteList}
        isMobile={isMobile || isApple}
      />
      <PopularGifts />
      <TrendingCards
        products={products}
        favouriteList={favouriteList}
        isMobile={isMobile || isApple}
      />
      <CelebrityCards
        products={products}
        favouriteList={favouriteList}
        isMobile={isMobile || isApple}
      />
      <KidsCards
        products={products}
        favouriteList={favouriteList}
        isMobile={isMobile || isApple}
      />
      <QRPanel />
      <Review />
      <Footer isLanding={true} />
    </Box>
  );
};

export default HomeScreen;
