import {
  FAVOURITE_LIST_FAIL,
  FAVOURITE_LIST_REQUEST,
  FAVOURITE_LIST_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  favourites: [],
  loading: false,
  error: null,
};

const favouriteListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FAVOURITE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAVOURITE_LIST_SUCCESS:
      return {
        ...state,
        favourites: payload,
        loading: false,
      };
    case FAVOURITE_LIST_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default favouriteListReducer;
